<!-- 
--  Página com formulário para nova cotação de frete.
--->

<script>
import TiposDeCampo from "../components/campos_dados/tipos_de_campo";
import CampoTexto from "../components/campos_dados/campo_texto";
import CampoSelect from "../components/campos_dados/campo_select";
import CampoCheckbox from "../components/campos_dados/campo_checkbox";
import { fecharPagina } from "../functions/fechar_pagina";

/** Campos básicos de informação para cotação de frete. */
const camposBases = {
  tipoCalculo: new CampoSelect({
    nome: 'tipo_calculo',
    label: 'Cálculo (canal de vendas)',
    icone: 'fas fa-calculator',
    // TODO
    valor: '',
    // TODO:
    itens: ['TODO', 'TODO'],
    regras: [
      value => {
        if (value) return true;
        return 'Informe um tipo de cálculo.';
      }
    ],
  }),
  cepDestino: new CampoTexto({
    nome: 'cep_destino',
    label: 'CEP destino',
    icone: 'fas fa-map-marker-alt',
    valor: '',
    regras: [
      value => {
        if (value) return true;
        return 'Informe um CEP.';
      }
    ],
  }),
};

// TODO: apenas o visual foi criado.
/** Campos com o total dos produtos nessa cotação. */
const camposTotal = {
  valor: new CampoTexto({
    nome: 'total',
    label: 'Total',
    icone: 'fas fa-poll-h',
    valor: '',
    regras: [
      value => {
        if (value) return true;
        return 'Informe um CEP.';
      }
    ],
  }),
  produtos: new CampoTexto({
    nome: 'produtos',
    label: 'Produtos',
    icone: 'fas fa-box',
    valor: '',
    regras: [
      value => {
        if (value) return true;
        return 'Informe um CEP.';
      }
    ],
  }),
  peso: new CampoTexto({
    nome: 'peso',
    label: 'Peso',
    icone: 'fas fa-weight-hanging',
    valor: '',
    regras: [
      value => {
        if (value) return true;
        return 'Informe um CEP.';
      }
    ],
  }),
  cubagem: new CampoTexto({
    nome: 'cubagem',
    label: 'Cubagem',
    icone: 'fas fa-cube',
    valor: '',
    regras: [
      value => {
        if (value) return true;
        return 'Informe um CEP.';
      }
    ],
  }),
  volume: new CampoTexto({
    nome: 'volume',
    label: 'Volume',
    icone: 'fas fa-sort-amount-up-alt',
    valor: '',
    regras: [
      value => {
        if (value) return true;
        return 'Informe um CEP.';
      }
    ],
  }),
};

/** Representa um produto, com seus campos. */
class Produto {
  codigoProduto = new CampoTexto({
    nome: 'codigo_produto',
    label: 'Código do produto (SKU)',
    icone: 'fas fa-barcode',
    valor: '',
    regras: [
      value => {
        if (!value)
          return 'Informe o SKU do produto.';

        return true;
      }
    ],
  });
  qtdDeCaixas = new CampoTexto({
    nome: 'qtd_de_caixas',
    label: 'Quantidade de caixas',
    icone: 'fas fa-boxes',
    valor: 1,
    regras: [
      value => {
        value = Number(value);

        if (!Number.isInteger(value))
          return 'Número inválido.';

        if (value < 1)
          return 'Informe a qtd. de caixas.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor * this.qtdDeCaixas.valor;
    }
  });
  comprimento = new CampoTexto({
    nome: 'comprimento',
    label: 'Comprimento (m)',
    icone: 'fas fa-ruler-horizontal',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o comprimento.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor * this.qtdDeCaixas.valor;
    }
  });
  largura = new CampoTexto({
    nome: 'largura',
    label: 'Largura (m)',
    icone: 'fas fa-ruler',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a largura.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor * this.qtdDeCaixas.valor;
    }
  });
  altura = new CampoTexto({
    nome: 'altura',
    label: 'Altura (m)',
    icone: 'fas fa-ruler-vertical',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a altura.';

        return true;
      }
    ],
    numerico: true,
    aoMudar: () => {
      this.cubagem.valor = this.altura.valor * this.comprimento.valor * this.largura.valor * this.qtdDeCaixas.valor;
    }
  });
  cubagem = new CampoTexto({
    nome: 'cubagem',
    label: 'Cubagem (m³)',
    icone: 'fas fa-cube',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe a cubagem.';

        return true;
      }
    ],
    bloqueado: true,
    numerico: true,
  });
  peso = new CampoTexto({
    nome: 'peso',
    label: 'Peso (kg)',
    icone: 'fas fa-weight-hanging',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o  peso.';

        return true;
      }
    ],
    numerico: true,
  });
  valor = new CampoTexto({
    nome: 'valor',
    label: 'Valor (R$)',
    icone: 'fas fa-dollar-sign',
    valor: '',
    regras: [
      value => {
        value = Number(value);

        if (value <= 0)
          return 'Informe o preço.';

        return true;
      }
    ],
    numerico: true,
  });
};

/** Gerencia o estado de exibição dos produtos. */
const estadoProdutos = {
  /** 
   * Lista com os produtos. Inicialmente, 
   * possui um produto.
   * @type {Produto[]} 
   */
  estadoAtual: [
    new Produto(),
  ],

  qtdProdutos: 1,

  /**
   * Adiciona um novo produto na lista.
   */
  adicionarProduto() {
    this.qtdProdutos++;
    this.estadoAtual.push(new Produto());
  },

  /**   
   * Remove um produto da lista.
   * @param {number} index - Index do produto na lista.
   */
  removerProduto(index) {
    this.qtdProdutos--;
    this.estadoAtual.splice(index, 1);
  }
}

/** Gerencia o estado para exibição do formulário de mais opções. */
const estadoOpcoesAvancadas = {
  estadoAtual: false,

  abrirOpcoesAvancadas() {
    this.estadoAtual = true;
  },

  fecharOpcoesAvancadas() {
    this.estadoAtual = false;
  },
}

/** Campos com opções adicionais para a cotação de frete. */
const camposOpcoesAvancadas = {
  vendedor: new CampoSelect({
    nome: 'vendedor',
    label: 'Vendedor',
    icone: 'fas fa-user-tag',
    valor: '',
    // TODO:
    itens: ['TODO', 'TODO'],
    regras: [
      value => {
        if (value) return true;
        return 'Informe um vendedor.'
      }
    ],
  }),
  tipoPessoa: new CampoSelect({
    nome: 'tipo_pessoa',
    label: 'Tipo de pessoa',
    icone: 'fas fa-male',
    valor: 'Física',
    itens: ['Física', 'Jurídica'],
    regras: [
      value => {
        if (value) return true;
        return 'Informe um tipo de pessoa.';
      }
    ],
  }),
  tipoFrete: new CampoSelect({
    nome: 'tipo_frete',
    label: 'Tipo de frete',
    icone: 'fas fa-share-square',
    valor: 'Enviar',
    itens: ['Enviar', 'Receber'],
    regras: [
      value => {
        if (value) return true;
        return 'Informe um tipo de frete.';
      }
    ],
  }),
  freteACobrar: new CampoCheckbox({
    nome: 'frete_a_cobrar',
    label: 'Frete a cobrar',
    icone: 'fas fa-hand-holding-usd',
    valor: false,
    regras: [],
  }),
  dificilAcesso: new CampoCheckbox({
    nome: 'dificil_acesso',
    label: 'Difícil acesso',
    icone: 'fas fa-mountain',
    valor: false,
    regras: [],
  }),
  dificilManuseio: new CampoCheckbox({
    nome: 'dificil_manuseio',
    label: 'Difícil manuseio',
    icone: 'fas fa-people-carry',
    valor: false,
    regras: [],
  }),
  entregarEmAeroporto: new CampoCheckbox({
    nome: 'entregar_em_aeroporto',
    label: 'Entregar em aeroporto',
    icone: 'fas fa-plane-arrival',
    valor: false,
    regras: [],
  }),
  entregarEmPorto: new CampoCheckbox({
    nome: 'entregar_em_porto',
    label: 'Entregar em porto',
    icone: 'fas fa-anchor',
    valor: false,
    regras: [],
  }),
  atendimentoForaDeHorario: new CampoCheckbox({
    nome: 'atendimento_fora_de_horario',
    label: 'Atendimento fora de horário',
    icone: 'fas fa-clock',
    valor: false,
    regras: [],
  }),
  zonaM: new CampoCheckbox({
    nome: 'zona_m',
    label: 'Zona M, restrição de caminhões',
    icone: 'fas fa-ban',
    valor: false,
    regras: [],
  }),
  naoDeclaraReaisCorreios: new CampoCheckbox({
    nome: 'nao_declara_reais_correios',
    label: 'Não declara R$ correios',
    icone: 'fas fa-dollar-sign',
    valor: false,
    regras: [],
  }),
  cargaPaletizada: new CampoCheckbox({
    nome: 'carga_paletizada',
    label: 'Carga paletizada',
    icone: 'fas fa-pallet',
    valor: false,
    regras: [],
    aoClicar: () => {
      if (camposOpcoesAvancadas.qtdDePaletes.bloqueado) {
        camposOpcoesAvancadas.qtdDePaletes.bloqueado = false;
        camposOpcoesAvancadas.jaPaletizada.bloqueado = false;
      } else {
        camposOpcoesAvancadas.qtdDePaletes.bloqueado = true;
        camposOpcoesAvancadas.qtdDePaletes.valor = '';
        camposOpcoesAvancadas.jaPaletizada.bloqueado = true;
        camposOpcoesAvancadas.jaPaletizada.valor = '';
      }
    },
  }),
  qtdDePaletes: new CampoTexto({
    nome: 'qtd_de_paletes',
    label: 'Qtd. de paletes',
    icone: 'fas fa-pallet',
    valor: '',
    regras: [],
    numerico: true,
    bloqueado: true,
  }),
  jaPaletizada: new CampoCheckbox({
    nome: 'ja_paletizada',
    label: 'Já paletizada',
    icone: 'fas fa-pallet',
    valor: false,
    regras: [],
    bloqueado: true,
  }),
  diasArmazenamento: new CampoTexto({
    nome: 'dias_armazenamento',
    label: 'Dias de armazenamento',
    icone: 'fas fa-calendar-alt',
    valor: '',
    regras: [],
    numerico: true,
  }),
  isentoIcms: new CampoCheckbox({
    nome: 'isento_icms',
    label: 'Isento de ICMS',
    icone: 'fas fa-comments-dollar',
    valor: false,
    regras: [],
  }),
  maoPropria: new CampoCheckbox({
    nome: 'mao_propria',
    label: 'Mão própria',
    icone: 'fas fa-hand-paper',
    valor: false,
    regras: [],
  }),
  avisoRecebimento: new CampoCheckbox({
    nome: 'aviso_recebimento',
    label: 'Aviso de recebimento',
    icone: 'fas fa-exclamation-triangle',
    valor: false,
    regras: [],
  }),
  redespacho: new CampoCheckbox({
    nome: 'redespacho',
    label: 'Redespacho',
    icone: 'fas fa-map-marker-alt',
    valor: false,
    regras: [],
    aoClicar: () => {
      if (camposOpcoesAvancadas.redespachoAte.bloqueado) {
        camposOpcoesAvancadas.redespachoAte.bloqueado = false;
      } else {
        camposOpcoesAvancadas.redespachoAte.bloqueado = true;
        camposOpcoesAvancadas.redespachoAte.valor = '';
      }
    }
  }),
  redespachoAte: new CampoTexto({
    nome: 'redespacho_ate',
    label: 'Até',
    icone: 'fas fa-map-marker-alt',
    valor: '',
    regras: [],
    bloqueado: true,
  }),
};

async function cotarFrete() {
  this.$refs.form.validate();
}

export default {
  name: 'NovaCotacaoFrete',

  data: () => ({
    estadoOpcoesAvancadas,
    TiposDeCampo,
    camposBases,
    camposTotal,
    camposOpcoesAvancadas,
    estadoProdutos,
  }),

  methods: {
    fecharPagina,
    cotarFrete,
  },
}

</script>

<template>
  <article>
    <!-- Título. -->
    <v-toolbar flat>
      <v-icon size="100">
        fas fa-truck
      </v-icon>
      <v-toolbar-title class="pl-4" style="font-size: 24px">
        Nova cotação
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="fecharPagina">
        <v-icon>fas fa-times</v-icon>
      </v-app-bar-nav-icon>
    </v-toolbar>

    <!-- 
      Resumo da cotação.
      Informações mais importantes, soma dos produtos, etc.
    -->
    <v-card outlined disabled>
      <v-card-text>
        <v-row dense align="center" justify="center">
          <v-col cols="12" sm="4" md="2">
            <h1 class="title">
              Resumo da cotação:
            </h1>
          </v-col>
          <v-col cols="12" sm="4" md="2" v-for="campo in camposTotal" :key="campo.nome">
            <v-text-field style="max-height: 40px;" dense outlined @click="campo.aoClicar" :disabled="campo.bloqueado"
              :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone" :name="campo.nome"
              :label="campo.label" autocomplete="off" v-model="campo.valor" color="#018656" @change="campo.aoMudar">
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Formulário. -->
    <v-form ref="form" @submit.prevent="cotarFrete">

      <!-- Campos básicos. -->
      <v-row dense style="margin-top: 10px;">
        <v-col cols="12" sm="6" md="3" v-for="campo in camposBases" :key="campo.nome">
          <v-text-field outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
            :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
            :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
            color="#018656" @change="campo.aoMudar">
          </v-text-field>

          <v-select outlined v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
            :disabled="campo.bloqueado" :append-icon="campo.icone" :name="campo.nome" :label="campo.label"
            autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
            @change="campo.aoMudar">
          </v-select>
        </v-col>

        <!-- Btn. Abrir opões avançadas. -->
        <v-col cols="12" sm="6" md="3">
          <v-btn @click="estadoOpcoesAvancadas.abrirOpcoesAvancadas()" color="#757575" type="button"
            style="color: #FFFFFF; width: 100%; height: 54px;">
            <v-icon dense class="mx-2">fas fa-chevron-circle-down</v-icon>
            OPÇÕES AVANÇADAS
          </v-btn>
        </v-col>

        <!-- Btn. Cotar frete. -->
        <v-col cols="12" sm="6" md="3">
          <v-btn type="submit" color="#018656" style="color: #FFFFFF; width: 100%;   height: 54px;">
            <v-icon dense class="mx-2">fas fa-check-circle</v-icon>
            COTAR FRETE
          </v-btn>
        </v-col>
      </v-row>

      <!-- Área com os produtos. -->
      <v-card outlined style="max-height: calc(100dvh - 330px);" class="overflow-y-auto">
        <v-card-text>
          <!-- Produto. -->
          <v-card outlined v-for="(produto, index) in estadoProdutos.estadoAtual" :key="index"
            style="margin-bottom: 15px;">
            <v-card-text>
              <v-row dense style="margin-top: 5px;">
                <v-col cols=" 12" sm="6" md="3" v-for="campo in produto" :key="campo.nome">
                  <v-text-field dense outlined v-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                    :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
                    :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                    color="#018656" @change="campo.aoMudar">
                  </v-text-field>
                </v-col>
              </v-row>

              <!-- Excluir produto. -->
              <v-row dense v-if="estadoProdutos.qtdProdutos > 1" style="margin-bottom: 5px;">
                <v-col cols="12" sm="6" md="3">
                  <v-btn outlined type="button" @click="estadoProdutos.removerProduto(index)" color="#FF5252"
                    style="color: #FF5252; width: 100%;">
                    <v-icon dense class="mx-2">fas fa-trash-alt</v-icon>
                    EXCLUIR PRODUTO
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <!-- Adicionar produto. -->
          <v-row dense style="margin-bottom: 5px;">
            <v-col cols="12">
              <v-btn outlined type="button" @click="estadoProdutos.adicionarProduto()" color="#FF5252"
                style="color: #018656; width: 100%;">
                <v-icon dense class="mx-2">fas fa-plus</v-icon>
                ADICIONAR PRODUTO
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>

    <!-- 
      Mais opções. Opções avançadas. 
      Diálogo ocupando a tela inteira.
    -->
    <v-dialog v-model="estadoOpcoesAvancadas.estadoAtual">
      <v-card outlined>
        <v-card-text style="margin-top: 20px;">
          <v-row justify="end" dense>
            <v-col cols="12" sm="6" md="3">
              <v-btn @click="estadoOpcoesAvancadas.fecharOpcoesAvancadas()" color="#FF5252" type="button"
                style="color: #FFFFFF; width: 100%; height: 36px;">
                <v-icon dense class="mx-2">fas fa-times-circle</v-icon>
                FECHAR
              </v-btn>
            </v-col>
          </v-row>

          <!-- Campos avançados. -->
          <v-row dense style="margin-top: 20px;">
            <v-col cols="12" sm="6" md="3" class="px-4" v-for="campo in camposOpcoesAvancadas" :key="campo.nome">
              <v-checkbox v-if="campo.tipoDeCampo == TiposDeCampo.checkbox" style="margin-bottom: 20px;"
                @click="campo.aoClicar" :disabled="campo.bloqueado" :prepend-icon="campo.icone" :name="campo.nome"
                :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras" color="#018656"
                @change="campo.aoMudar">
              </v-checkbox>

              <v-select flat v-else-if="campo.tipoDeCampo == TiposDeCampo.select" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :prepend-icon="campo.icone" :name="campo.nome" :label="campo.label"
                autocomplete="off" v-model="campo.valor" :rules="campo.regras" :items="campo.itens" color="#018656"
                @change="campo.aoMudar">
              </v-select>

              <v-text-field flat v-else-if="campo.tipoDeCampo == TiposDeCampo.texto" @click="campo.aoClicar"
                :disabled="campo.bloqueado" :type="campo.numerico ? 'number' : 'text'" :prepend-icon="campo.icone"
                :name="campo.nome" :label="campo.label" autocomplete="off" v-model="campo.valor" :rules="campo.regras"
                color="#018656" @change="campo.aoMudar">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </article>
</template>