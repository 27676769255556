import TiposDeCampo from "./tipos_de_campo";

/**
 * Classe base para campos de entrada de dados.
 * @abstract
 */
class Campo {
  /**
   * @constructor
   * @param {Object} params
   * @param {string} params.nome - Nome do campo, de preferência em `snake_case`.
   * @param {string} params.label - Texto exibido dentro do campo.
   * @param {string} params.icone - Ícone usado pelo campo.
   * @param {any} params.valor - Valor atual do campo.
   * @param {Function[]} params.regras - Lista de funções para checar o valor inserido no campo.
   * @param {boolean} params.bloqueado - Informa se o campo está bloqueado para uso.
   * @param {Function} params.aoClicar - Ação ao clicar no campo.
   * @param {Function} params.aoMudar - Ação ao mudar o valor do campo.
   * @param {string} params.explicacao - Explicação exibida ao colocar o mouse por cima do campo.
   */
  constructor(
    { nome, label, icone, valor, regras, bloqueado = false, aoClicar = () => { }, aoMudar = () => { }, explicacao = '' }
  ) {
    this.nome = nome;
    this.label = label;
    this.icone = icone;
    this.valor = valor;
    this.regras = regras;
    this.bloqueado = bloqueado;
    this.aoClicar = aoClicar;
    this.aoMudar = aoMudar;
    this.explicacao = explicacao;
  }

  /** @type {string} */
  nome;
  /** @type {string} */
  label;
  /** @type {string} */
  icone;
  /** @type {any} */
  valor;
  /** @type {Function[]} */
  regras;
  /** @type {boolean} */
  bloqueado;
  /** @type {Function} */
  aoClicar;
  /** @type {Function} */
  aoMudar;
  /** @type {string} */
  explicacao;

  /**
   * O tipo de campo deve ser adicionado em quem extender.
   * @type {TiposDeCampo}
   */
  tipoDeCampo;
}

export default Campo;