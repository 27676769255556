import TiposDeCampo from "./tipos_de_campo";
import Campo from "./campo";

/**
 * Representa um campo de caixa de seleção.
 * 
 * - Vuetify `<v-checkbox>`.
 * 
 * `Exemplo`:
 * 
 * ```vue
 * <script>
 *   import CampoCheckbox from '../campo_checkbox;
 *   import TiposDeCampo from '../tipos_de_campo;
 *  
 *   const campo = new CampoCheckbox({
 *     nome: 'campo',
 *     label: 'Campo',
 *     icone: 'fas fa-comments-dollar',
 *     valor: false,
 *     regras: [],
 *   });
 *  
 *   export default {
 *     data: () => {campo, TiposDeCampo}, 
 *   }
 * <script>
 * 
 * <template>
 *   <v-checkbox 
 *     v-if="campo.tipoDeCampo == TiposDeCampo.checkbox" 
 *     style="margin-bottom: 20px;" 
 *     \@click="campo.aoClicar" 
 *     :disabled="campo.bloqueado" 
 *     :prepend-icon="campo.icone" 
 *     :name="campo.nome" 
 *     :label="campo.label" 
 *     autocomplete="off" 
 *     v-model="campo.valor" 
 *     :rules="campo.regras" 
 *     color="#018656" 
 *     \@change="campo.aoMudar"
 *   ></v-checkbox>
 * </template>
 * ```
 * 
 * @extends Campo
 */
class CampoCheckbox extends Campo {
  /**
   * @constructor
   * @param {Object} params
   * @param {string} params.nome - Nome do campo, de preferência em `snake_case`.
   * @param {string} params.label - Texto exibido dentro do campo.
   * @param {string} params.icone - Ícone usado pelo campo.
   * @param {boolean} params.valor - Valor atual do campo. Se está checado ou não.
   * @param {Function[]} params.regras - Lista de funções para checar o valor inserido no campo.
   * @param {boolean} params.bloqueado - Informa se o campo está bloqueado para uso.
   * @param {Function} params.aoClicar - Ação ao clicar no campo.
   * @param {Function} params.aoMudar - Ação ao mudar o valor do campo.
   * @param {string} params.explicacao - Explicação exibida ao colocar o mouse por cima do campo.
  */
  constructor(
    { nome, label, icone, valor, regras, bloqueado = false, aoClicar = () => { }, aoMudar = () => { }, explicacao = '' }
  ) {
    super({
      nome: nome,
      label: label,
      icone: icone,
      valor: valor,
      regras: regras,
      bloqueado: bloqueado,
      aoClicar: aoClicar,
      aoMudar: aoMudar,
      explicacao: explicacao,
    });
  };

  tipoDeCampo = TiposDeCampo.checkbox;
};

export default CampoCheckbox;