/**
 * Tipos de campo de entrada de dados.
 * @enum {string}
 */
const TiposDeCampo = {
  texto: 'campo-texto',
  select: 'campo-select',
  checkbox: 'campo-checkbox',
}

export default TiposDeCampo;