import TiposDeCampo from "./tipos_de_campo";
import Campo from "./campo";

/**
 * Representa um campo de texto.
 * 
 * - Vuetify `<v-text-field>`.
 * 
 * `Exemplo`:
 * 
 * ```vue
 * <script>
 *   import CampoTexto from '../campo_texto;
 *   import TiposDeCampo from '../tipos_de_campo;
 *  
 *   const campo = new CampoTexto({
 *     nome: 'campo',
 *     label: 'Campo',
 *     icone: 'fas fa-comments-dollar',
 *     valor: 'Um',
 *     regras: [],
 *   });
 *  
 *   export default {
 *     data: () => {campo, TiposDeCampo}, 
 *   }
 * <script>
 * 
 * <template>
 *   <v-text-field  
 *     v-if="campo.tipoDeCampo == TiposDeCampo.texto" 
 *     \@click="campo.aoClicar"
 *     :disabled="campo.bloqueado" 
 *     :type="campo.numerico ? 'number' : 'text'" :append-icon="campo.icone"
 *     :name="campo.nome" 
 *     :label="campo.label" 
 *     autocomplete="off" 
 *     v-model="campo.valor" 
 *     :rules="campo.regras"
 *     color="#018656" 
 *     \@change="campo.aoMudar"
 *   ></v-text-field>
 * </template>
 * ```
 * 
 * @extends Campo
 */
class CampoTexto extends Campo {
  /**
   * @constructor
   * @param {Object} params
   * @param {string} params.nome - Nome do campo, de preferência em `snake_case`.
   * @param {string} params.label - Texto exibido dentro do campo.
   * @param {string} params.icone - Ícone usado pelo campo.
   * @param {string} params.valor - Valor atual do campo.
   * @param {Function[]} params.regras - Lista de funções para checar o valor inserido no campo.
   * @param {boolean} params.bloqueado - Informa se o campo está bloqueado para uso.
   * @param {Function} params.aoClicar - Ação ao clicar no campo.
   * @param {Function} params.aoMudar - Ação ao mudar o valor do campo.
   * @param {boolean} params.numerico - Informa se é um campo numérico. Como padrão: `false`.
   * @param {string} params.explicacao - Explicação exibida ao colocar o mouse por cima do campo. 
   */
  constructor(
    { nome, label, icone, valor, regras, bloqueado = false, aoClicar = () => { }, aoMudar = () => { }, explicacao = '', numerico = false, }
  ) {
    super({
      nome: nome,
      label: label,
      icone: icone,
      valor: valor,
      regras: regras,
      bloqueado: bloqueado,
      aoClicar: aoClicar,
      aoMudar: aoMudar,
      explicacao: explicacao,
    });

    this.numerico = numerico;
  }

  /** @type {boolean} */
  numerico;

  tipoDeCampo = TiposDeCampo.texto;
}

export default CampoTexto;